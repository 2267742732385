import React, { useEffect, useState } from 'react'
// import dynamic from 'next/dynamic'
import useMobileDetect from 'helpers/mobile-detect'
import Image from 'next/image'

import styles from 'components/home/simple-section/simple-section.module.scss'
import { LazyBackgroundImage } from '../../share/withImages'

// const ServiceItem = dynamic(() => import('./service-item'))
import ServiceItem from './service-item'

export default function SimpleSection (props) {
  const [imageUrl, setImageUrl] = useState('')
  const bgImage = 'url(' + imageUrl + ') no-repeat center center / cover #000000'
  const currentDevice = useMobileDetect()

  useEffect(() => {
    if (currentDevice.isMobile() && currentDevice.width < 768) {
      setImageUrl('/static/images/bg-m-simple-section-mb.jpg')
    } else {
      setImageUrl('/static/images/bg-simple-section-dk.jpg')
    }
    LazyBackgroundImage({ src: imageUrl, loaded: true })
  }, [currentDevice.width])

  return (
    <section className={styles['simple-section']}>
      <div className={styles['simple-section__wrapper'] + ' lazy-background'} style={{ background: bgImage }}>
        <div className={styles['simple-section__caption'] + ' text-center container'}>
          <h3 className={styles['simple-section__title']}>get fast results - or your money back</h3>
          <p className="col-12 col-md-9">
            No templates here - our #1 rated online, personalized, total body
            transformation program combines delicious meals and exciting
            workouts to create a leaner, stronger and healthier version of you
            in as little as 3 weeks.
          </p>
          <div className={styles['simple-section__list-symbol'] + ' row col-12 col-md-8'}>
            <ServiceItem title={'Set your goals &<br /> customize'} titleMobile={'Set goals &<br /> customize'} image={'/static/images/daily-diet.svg'}/>
            <ServiceItem title={'Daily diet &<br /> workouts'} titleMobile={'Daily diet &<br /> workouts'} image={'/static/images/cup.svg'}/>
            <ServiceItem title={'Track <br /> your progress'} titleMobile={'Track your <br /> progress'} image={'/static/images/girl.svg'}/>
          </div>
          <div className={styles['simple-section__group-machine']}>
            <Image className={ styles['simple-section__imac'] + ' img-fluid' } src="/static/images/imac.png" alt={'iMac'} width={422} height={350} />
            <Image className={ styles['simple-section__ipad'] + ' img-fluid' } src="/static/images/ipad.png" alt={'iPad'} width={190} height={266} />
            <Image className={ styles['simple-section__iphone'] + ' img-fluid' } src="/static/images/mobile.png" alt={'mobile'} width={122} height={228} />
            <Image className={ styles['simple-section__laptop'] + ' img-fluid' } src="/static/images/laptop.png" alt={'laptop'} width={424} height={253} />
          </div>
        </div>
      </div>
    </section>
  )
}
