import React from 'react'
import Image from 'next/image'

export default function ServiceItem ({ image, title, titleMobile }) {
  return (
    <div className="card no-border no-background col-4 text-white">
      <Image
        className="card-img-top"
        src={image}
        alt={'Set your goals & customize'}
        width={52}
        height={52}
        loading="lazy"
      />
      <span className={'d-none d-md-block'} dangerouslySetInnerHTML={{ __html: title }}></span>
      <span className={'d-block d-md-none'} dangerouslySetInnerHTML={{ __html: titleMobile }}></span>
    </div>
  )
}
