import React, { useEffect, useState } from 'react'
import { smoothScrollTo } from 'helpers/scrollTo'
import Image from 'next/image'

import styles from './fast-easy.module.scss'

export default function Index (props) {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  const scrollToElement = (e) => {
    e.preventDefault()
    if (mounted && e.target) {
      const buttonId = e.target.getAttribute('name')
      const element = buttonId && document.getElementById(buttonId)
      if (element) {
        smoothScrollTo(`#${buttonId}`, 'center')
      }
    }
  }

  return (
    <section className={styles['fast-easy']}>
      <div className='wrapper'>
        <div className={styles['fast-easy__caption'] + ' container'}>
          <h3 className={styles['fast-easy__title']}>FAST, EASY AND EFFECTIVE PROGRAMS</h3>
          <p className={styles['fast-easy__sub-title'] + ' col-12 col-md-10 col-lg-9 ms-auto me-auto'}>
            With so much misinformation in the market, we wanted to do all the
            work for you to make your journey as easy as possible. Looking and
            feeling your best should not be a chore - now you can simply follow
            our easy roadmap to success, and watch the weight fall off.
          </p>
          <div className={styles['fast-easy__list-services'] + ' row'}>
            <div name="workout" className='card col-5 col-md-2' onClick={scrollToElement}>
              <Image
                name="workout"
                className='card-img-top'
                src='/static/images/custom-workout.svg'
                alt={'Custom workouts'}
                width={32}
                height={32}
              />
              <span>Custom workouts</span>
            </div>
            <div name="tailored" className='card col-5 col-md-2' onClick={scrollToElement}>
              <Image
                name="tailored"
                className='card-img-top'
                src='/static/images/tailored_diet.svg'
                alt={'Tailored Diet'}
                width={32}
                height={32}
              />
              <span>Tailored Diet</span>
            </div>
            <div name="analytics" className='card col-5 col-md-2' onClick={scrollToElement}>
              <Image
                name="analytics"
                className='card-img-top'
                src='/static/images/analytic.svg'
                alt={'Analytics'}
                width={32}
                height={32}
              />
              <span>Analytics</span>
            </div>
            <div name="social-group" className='card col-5 col-md-2' onClick={scrollToElement}>
              <Image
                name="social-group"
                className='card-img-top'
                src='/static/images/comunity.svg'
                alt={'Community'}
                width={32}
                height={32}
              />
              <span>Community</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
