import React, { useEffect, useState } from 'react'
import useMobileDetect from 'helpers/mobile-detect'
import { smoothScrollTo } from 'helpers/scrollTo'
import { LazyBackgroundImage, LazyImage } from 'components/share/withImages'

import styles from 'components/home/expect-result/expect-result.module.scss'

export default function ExpectResult (props) {
  // eslint-disable-next-line no-unused-vars
  const [weekList, setWeekList] = useState([
    {
      week: 'WK',
      id: '1',
      title: 'Visible',
      subTitle: 'weight-loss',
      image: '/static/images/w1.png'
    },
    {
      week: 'WK',
      id: '2',
      title: 'Increased',
      subTitle: 'energy',
      image: '/static/images/w2.png'
    },
    {
      week: 'WK',
      id: '3',
      title: 'A leaner',
      subTitle: 'physique',
      image: '/static/images/w3.png'
    },
    {
      week: 'WK',
      id: '12',
      title: 'Sculpted',
      subTitle: 'body',
      image: '/static/images/w12.png'
    }
  ])
  const currentDevice = useMobileDetect()
  const [imageUrl, setImageUrl] = useState('/static/images/bg-expect-result.jpg')
  const bgImage = 'url(' + imageUrl + ') no-repeat center top / cover #000000'

  const moveToProgram = () => {
    const element = document.getElementById('choose-program')
    if (element) {
      smoothScrollTo('#choose-program')
    }
  }

  useEffect(() => {
    if (currentDevice.isMobile() && currentDevice.width < 768) {
      setImageUrl('/static/images/bg-m-expect-result-mb.jpg')
    } else {
      setImageUrl('/static/images/bg-expect-result.jpg')
    }
    LazyBackgroundImage({ src: imageUrl, alt: 'Placeholder', loaded: currentDevice.width > 0 })
  }, [currentDevice.width])

  return (
    <section className={styles['expect-result']}>
      <div className={styles['expect-result__wrapper'] + ' lazy-background'} style={{ background: bgImage }}>
        <h3 className={styles['expect-result__title']}>
          EXPECT <span className={styles['expect-result__text-yellow']}>RESULTS</span> FROM DAY 1
        </h3>
        <div className={styles['expect-result__content']}>
          <p>
            Our program has a <span>100% SUCCESS RATE</span> from working with
            the world's greatest athletes, to everyday folks just wanting to
            look better, feel better, and live better. Imagine how easy it will
            be with Mike in your corner!
          </p>
        </div>
        <ul className={styles['expect-result__progress']}>
          {weekList.map((week, index) => {
            return (
              <li
                key={index}
                className={`${styles['expect-result__card-item']}` +
                    ` ${index === weekList.length - 1 ? `${styles['expect-result__card-item--active']}` : ''}`}
              >
                <LazyImage width={121} height={128} className='card-img-top img-fluid' src={week.image} alt={`${week.title} ${week.subTitle}`} />
                <div className={styles['expect-result__thumb-caption']}>
                  <div className={styles['expect-result__thumb-caption--week']}>{week.week}<br/>{week.id}</div>
                </div>
                <span className='title'>{week.title}</span>
                <span className='sub-title'>{week.subTitle}</span>
              </li>
            )
          })}
        </ul>
        <button className={styles['expect-result__see-programs'] + ' btn'} onClick={moveToProgram}>
          {' '}
          See programs & pricing{' '}
        </button>
      </div>
    </section>
  )
}
