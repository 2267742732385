import React from 'react'
import PropTypes from 'prop-types'

import styles from 'components/share/logo-bar/logo-bar.module.scss'
import Image from 'next/image'
export default function LogoBar () {
  return (
    <section className={styles['logo-bar']}>
      <div className='container-fluid p-3'>
        <div className={styles['logo-bar__wrapper']}>
          <span className={styles['logo-bar__title']}>AS FEATURED IN</span>
          <div className={styles['logo-bar__list-logo'] + ' flex-grow-1'}>
            <div className={styles['logo-bar__featured__logos']}>
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Womenshealthlogo_pink.svg' width='115' height='24' alt={"Women's Health"} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Muscle__and__Fitness.svg' width='62' height='27' alt={'Muscle & Fitness'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Cosmopolitan_logo_pink.svg' width='113' height='22' alt={'Cosmopolitan'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Yahoo_logo_purple.svg' width='97' height='21' alt={'Yahoo'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/espn.svg' alt={'ESPN'} width='75' height='21' />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Asset1.svg' alt={"Men's Health"} width='107' height='24' />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/American_Broadcasting_Company_Logo.svg' width='24' height='23' alt={'ABC'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/variety.svg' width='86' height='24' alt={'Variety'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Group2.svg' width='46' height='22' alt={'Fox'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/elle.svg' width='53' height='28' alt={'Elle'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/mens-fitness.svg' width='103' height='20' alt={"Men's Fitness"} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Group78.svg' width='93' height='25' alt={'Sports Illustrated'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/Glamour-Logo-pink.svg' width='101' height='25' alt={'Glamour'} />
              <Image className={styles['logo-bar__logo-item']} src='/static/images/logo/ask-men-logo.svg' width='98' height='26' alt={'askmen'} />
            </div>
          </div>
          <Image
            className={styles['mobile-hidden']}
            loading="lazy"
            src='/static/images/logo/joeroganepisodelogo.png'
            alt={'The Joe Rogan Experience'}
            width='73'
            height='73'
          />
        </div>
      </div>
    </section>
  )
}

LogoBar.propTypes = {
  images: PropTypes.array,
  style: PropTypes.any
}
