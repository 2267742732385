import React, { useEffect, useState } from 'react'
import useMobileDetect from 'helpers/mobile-detect'
import { LazyBackgroundImage } from 'components/share/withImages'
// import dynamic from 'next/dynamic'
import Image from 'next/image'

import styles from './service-detail.module.scss'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// const FastEasy = dynamic(() => import('../fast-easy/index'))
import FastEasy from '../fast-easy/index'

export default function ServicesDetail (props) {
  const [imageUrl, setImageUrl] = useState('/static/images/bg-what-we-offer.jpg')
  const currentDevice = useMobileDetect()

  useEffect(() => {
    if (currentDevice.isMobile() && currentDevice.width < 768) {
      setImageUrl('/static/images/bg-m-what-we-offer-mb.jpg')
    } else {
      setImageUrl('/static/images/bg-what-we-offer.jpg')
    }
    LazyBackgroundImage({ src: imageUrl, alt: 'Placeholder', loaded: currentDevice.width > 0 })
  }, [currentDevice.width])

  const bgImage = 'url(' + imageUrl + ') no-repeat #000000'
  return (
    <section className={styles['services-detail']}>
      <div className="wrapper lazy-background" style={{ background: bgImage }}>
        <FastEasy />
        <div className="container">
          <div className={styles['services-detail__row-item']}>
            <div id="workout" className="workout">
              <div className={styles['services-detail__card-item'] + ' row'}>
                <div className="col-12 col-md-7">
                  <div className="media">
                    <Image
                      className={styles['services-detail__title-icon'] + ' workout'}
                      src="/static/images/custom-workout.svg"
                      alt="Custom workout"
                      width={46}
                      height={46}
                    />
                    <div className="media-body">
                      <h3 className={styles['services-detail__title']}>CUSTOMIZED WORKOUTS</h3>
                      <p className="col-12 col-md-11 pc">
                        Our workouts are fun, fast, and exciting! <br />
                        Don't worry, you don't have to be an expert to start. <br />
                        We tailor your workout to your experience levels and goals.
                        From a simple walking program to our super intense "Beast
                        Mode," our team knows exactly what you need and provides all
                        the instruction to guide you along the way.
                      </p>
                      <p className="mobile">
                        Developed by our team of Registered Dietitians and Certified
                        Trainers under the direction of Hall of Fame winning and 4X
                        MMA Trainer Of The Year!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 text-center">
                  <Image
                    className={'img-fluid'}
                    src="/static/images/bg-custom-workout.png"
                    alt="Custom Workout"
                    width={283}
                    height={414}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div id="tailored" className="tailored">
              <div className={styles['services-detail__card-item'] + ' row'}>
                <div className="col-12 col-md-6 order-md-1">
                  <div className="media">
                    <Image
                      src="/static/images/tailored_diet.svg"
                      alt="Tailored Diet"
                      className={styles['services-detail__title-icon'] + ' diet'}
                      width={55}
                      height={46}
                      loading="lazy"
                    />
                    <div className="media-body mt-2">
                      <h3 className={styles['services-detail__title']}>Tailored Diet</h3>
                      <p className="">
                        Healthy food doesn’t have to be bland! <br /> Easy to
                        prepare, allergy and vegan-friendly nutrition plans with an
                        online shopping list. We know you’ll love our earth-grown
                        meals that help burn fat and build muscle.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-0 p-0">
                  <div className={styles['services-detail__food-list'] + ' row m-0'}>
                    <div className={styles['services-detail__food-list__col-item'] + ' col-6 mb-3 pr-2'}>
                      <div className="thumbnail">
                        <Image loading="lazy" className={'img-thumbnail img-fluid'} src='/static/images/food2.png' alt='The Breakfast Bowl' width='167' height='222' />
                        <div className="caption">
                          <p>The Breakfast Bowl</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles['services-detail__food-list__col-item'] + ' col-6 mb-3 pl-2'}>
                      <div className={'thumbnail'}>
                        <Image loading="lazy" className={'img-thumbnail img-fluid'} src='/static/images/food1.png' alt='Almond butter snacks' width='167' height='222' />
                        <div className="caption">
                          <p>Almond butter snacks</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles['services-detail__food-list__col-item'] + ' col-6 mb-3 pr-2'}>
                      <div className="thumbnail">
                        <Image loading="lazy" className={'img-thumbnail img-fluid'} src='/static/images/food3.png' alt='Quinoa Salad' width='167' height='222' />
                        <div className="caption">
                          <p>Quinoa Salad</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles['services-detail__food-list__col-item'] + ' col-6 mb-3 pl-2'}>
                      <div className="thumbnail">
                        <Image loading="lazy" className={'img-thumbnail img-fluid'} src='/static/images/food4.png' alt='Grilled Salmon' width='167' height='222' />
                        <div className="caption">
                          <p>Grilled Salmon</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="analytics" className="analytics">
              <div className={styles['services-detail__card-item'] + ' row'}>
                <div className="col-12 col-md-6">
                  <div className="media">
                    <Image
                      className={styles['services-detail__title-icon'] + ' analytic'}
                      src="/static/images/analytic.svg"
                      alt="Analytic"
                      width={37}
                      height={46}
                      loading="lazy"
                    />
                    <div className="media-body mt-2">
                      <h2 className={`${styles['services-detail__title']}`}>Analytics & progress tracking</h2>
                      <p className="">
                        Set your goals and track your progress online with advanced
                        3D body modelling . Compete on the leaderboard against
                        thousands of other Dolce Dieters across 61 countries.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 offset-md-1">
                  <div className={styles['services-detail__mobile-list']}>
                    <Image src="/static/images/logo/mobile1.png" loading="lazy" alt="Mobile1" width={149} height={315} />
                    <Image src="/static/images/logo/mobile3.png" loading="lazy" alt="Mobile3" width={149} height={315} />
                    <Image src="/static/images/logo/mobile2.png" loading="lazy" alt="Mobile2" width={149} height={315} />

                  </div>
                </div>
              </div>
            </div>
            <div id="social-group" className="social-group">
              <div className={styles['services-detail__card-item'] + ' row'}>
                <div className="col-12 col-md-6 order-md-1">
                  <div className="media">
                    <Image
                      loading="lazy"
                      className={styles['services-detail__title-icon'] + ' community'}
                      src="/static/images/comunity.svg"
                      alt="Analytic"
                      width={52}
                      height={42}
                    />
                    <div className="media-body mt-2">
                      <h2 className={styles['services-detail__title']}>COMMUNITY SUPPORT</h2>
                      <p className="pc">
                        You don’t have to do it alone. Join thousands of other
                        members globally on the motivational Facebook group. Enjoy
                        weekly content to keep you motivated from Mike himself as
                        well as weightloss contests and recipe ideas.
                      </p>
                      <p className="mobile">
                        You don’t have to do it alone. Join over 3,000+ other
                        members globally on the motivational Facebook group. Enjoy
                        weekly content to keep you motivated from Mike himself as
                        well as weightloss contests and recipe ideas.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-0">
                  <a href="https://www.facebook.com/groups/dolcedietonlinecommunity/" className={`card ${styles['services-detail__facebook-banner']}`} target={'_blank'} rel="noreferrer">
                    <Image loading="lazy"width={446} height={201} alt="membership community" src="/static/images/membership_community.png" className={'card-img'} />
                    <span className="card-body">
                      <p className={`font-bold text-center h5 ${styles['facebook-group-name']}`}>
                        DOLCE DIET ONLINE COMMUNITY
                      </p >
                      <div className="text-center text-dark mb-3">Facebook Group</div>
                      <button className={`btn btn-primary w-100 ${styles['btn-facebook']}`}>
                        <FontAwesomeIcon icon={faFacebookSquare} className={'fab me-2'} />
                        Join Group
                      </button>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['services-detail__overlay']}>
          <div className={styles['services-detail__text-overlay']}>
            <span className={styles['services-detail__red-circle']}></span>
            <p>
              3.2 lbs. <br /> from goal
            </p>
          </div>
          <Image
            loading="lazy"
            className={styles['services-detail__bg-overlay'] + ' w-100'}
            src="/static/images/service-detail-br.svg"
            alt={'3.2 lbs. from goal'}
            width={1037}
            height={766}
          />
        </div>
      </div>
    </section>
  )
}
